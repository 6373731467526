import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { CurrencyPipe } from './pipes/currency.pipe';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HighlightOnHoverDirective } from './main/directives/input-restriction';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { auth_sso_configuration, AUTH_SSO_MSAL } from './main/auth_sso_configuration';
import { DateToFrenchPipe } from './pipes/date-to-french.pipe';
import { ForbiddenInterceptorService } from './main/service/forbidden-interceptor.service';
import { OfflineInterceptor } from './main/service/offline-interceptor.service';
import { LoaderInterceptor } from './main/service/interceptors/loader.interceptor';
// Register the French locale data
registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent,
        CurrencyPipe,
        HighlightOnHoverDirective,
        DateToFrenchPipe,
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        MsalModule
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ForbiddenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DatePipe
    ]
    ,
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ]
})

export class AppModule {

    constructor(private config: PrimeNGConfig) {

        this.config.setTranslation(
            {
                'apply': 'Appliquer',
                'clear': 'Vider'
            }
        );

        this.setLangFR();
        this.config.translationObserver.subscribe();

    };

    fr = {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
        today: 'Aujourd\'hui',
        clear: 'Vider',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Semaine'
    };

    setLangFR() {
        this.config.setTranslation(this.fr);
    }

}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(auth_sso_configuration);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
        loginFailedRoute: '/misc/access',

    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = AUTH_SSO_MSAL.protectedResourceMap;

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}
